import { storeToRefs } from 'pinia';
import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from './store';
// import { CMSIS_ROUTES } from '@/router';
// import { CmsisApi } from '@/api';

// import useLoading from '@/composables/loading';
// import { toast } from '@/components/ui/Toast';
import { Project } from './store';

interface Layout {
  isLoading: Ref<boolean>;
  project: Ref<Project>;
}
export const useLayout = (): Layout => {
  const store = useStore();
  const { project, isLoading } = storeToRefs(store);
  const { fetchStore } = store;
  const route = useRoute();
  const { params } = route;
  const projectId = ref(Number(params.projectId));

  // 商品の取得
  const get = async (id: number) => {
    if (id !== project.value.cmsisProjectId) {
      await fetchStore({ cmsisProjectId: id });
    }
  };

  (async () => {
    await get(projectId.value);
  })();

  return {
    isLoading,
    project
  };
};
